.screen-grub-greeting {
  .message {
    display: inline;
    color: black;
    background-color: #ddd;
  }
}

.screen-grub {
  cursor: none;
  color: white;
  font-family: monospace;

  .menu {
    margin: auto;
    width: 50%;
    height: 50%;
  }

  .entry {
    padding: 10px 10px;
    width: 100%;
    box-sizing: border-box;
  }

  .selected {
    background-color: #5ba4c5;//my best guess. maybe make is see through
    border-radius: 5px;
  }

  .countdown {
    margin-top: 30px;
    text-align: center;
  }
}
