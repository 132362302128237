$console-font-size: 12px;

.screen-console-boot {
  cursor: none;
  background-color: black;
  color: white;
  font-size: $console-font-size;
  font-family: monospace;

  div {
    margin-top: 2px;
    margin-left: 2px;
  }

  .boot-entry-name {
    margin-left: 15px;
    margin-bottom: $console-font-size;
  }
}

.plymouth {
  cursor: none;

  .kali-logo {
    margin: auto;
    width: 400px;
    height: 400px;
    position: relative;

    // image sizes:
    // logo: 336x250
    // container: 400x400
    // fade: 386x300
    // outline: 380x60
    // idea: padd them all to the same size (400x400px)
    img {
      box-sizing: border-box;
    }

    .logo {
      padding: 75px 32px;
    }

    .container {
      padding: 0px 0px;
    }

    .fade {
      padding: 50px 7px;
      // opacity: 0.5;
    }

    .outline {
      padding: 0px 10px;
    }
  }

  .logo-boot-animation {
    .outline {
      top: 340px;
      height: 60px;
    }
  }

  .logo-password, .logo-shutdown {
    .fade {
      opacity: 0.5;
    }

    .outline {
      display: none;
    }
  }
}

.screen-plymouth-decrypt {
  .password-div {
    display: flex;
    width: 200px;
    margin: auto;
    border-bottom: 3px solid blue;

    img {
      width: 10px;
      height: 18px;
    }

    input {
      cursor: none;
      caret-color: transparent;
      flex: 1;
      color: white;
      background-color: black;
    }
  }

  .status-text {
    color: white;
    text-align: center;
    margin-top: 10px;
  }
}

.screen-plymouth-check {
  .status-text {
    margin-top: 33px;
    color: white;
    text-align: center;
  }
}
