.fill-screen {
  position:absolute;
  top:0; left:0; bottom:0; right:0;
  width:100%; height:100%;
  border:none; margin:0; padding:0;
  overflow:hidden;
}

.bg {
  z-index: -10;
}

.v-flex {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.h-flex {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.expand {
  flex: 1;
}
