.status-bar {
  height: $bar-height;
  background-color: $bar-bg;
  color: #eeeeec;
  font-size: 12px;
  font-family: sans-serif;
  user-select: none;
  cursor: default;

  img {
    width: 14px;
    height: 14px;
  }

  .menu-trigger, .hostname, .clock {
    user-select: none !important;
    padding: 6px 5px;

    height: $bar-height;
    box-sizing: border-box;
  }

  .clock {
    font-weight: bold;
  }

  .menu-trigger-selected {
    border-bottom: 3px solid $blue;
    padding-bottom: 4px;
  }

  .menu-trigger-disabled {
    color: gray;
  }

  .menu-popup {
    background-color: $bar-bg;
    color: white;

    position: fixed;
    top: 27px;
    right: 0px;

    .menu-item {
      padding: 4px 0px 4px 30px;
      width: 225px;//TODO make smaller and fix positioning
      box-sizing: border-box;

      &:hover {
        background-color: $blue;
      }
    }

    .keybinding {
      color: #999;
      float: right;
      margin-right: 7px;
    }
  }
}
