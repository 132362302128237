.screen-login {
  button {
    background-color: #1a1b22;
    color: white;
    border-radius: 5px;
    padding: 5px 15px;
    border: none;
    outline: 0px;
    margin: 15px;
  }

  .login-box {
    background-color: #23414b;
    width: 350px;
    margin: auto;
    border-radius: 5px;
    padding-top: 20px;

    .user-icon {
      width: 80px;
      height: 80px;
      padding: 5px 20px 10px 20px;
    }

    .inputs {
      padding-bottom: 15px;

      input {
        background-color: #23353f;
        color: #ffffff;
        border-radius: 5px;
        margin: 5px 20px 5px 0px;
        padding: 0px 10px;
        height: 34px;
        width: 200px;
      }

      input::placeholder {
        color: #777;
        opacity: 1; /* Firefox */
      }
    }

    .wrong-password {
      color: white;
      background-color: red;
      text-align: center;
      padding: 15px 10px;
      font-family: sans-serif;
      font-size: 12px;
      font-weight: 550;
    }

    .buttons {
      background-color: $login-buttons-bg;
      border-radius: 0px 0px 5px 5px;

      #login {
        background-color: $blue;
      }
    }
  }

  .shutdown-dialog {
    background-color: #23414b;
    width: 450px;
    margin: auto;
    border-radius: 5px;
    padding-top: 20px;
    color: white;

    .icon {
      width: 40px;
      height: 40px;
      margin: 5px 20px 0px 30px;
    }

    .title {
      font-weight: bold;
      font-size: 12px;
      margin-bottom: 20px;
    }

    .text {
      font-size: 11px;
    }
  }
}
